<template>
  <div v-if="user" class="h-full overflow-y-scroll bg-gray-600">
    <ul>
      <li class="py-2">
        <div class="flex justify-center py-8">
          <img src="logo.png" alt="" class="h-20 rounded-full" />
        </div>
      </li>
      <li v-for="(menuItem, index) in menus" :key="index">
        <div
          @click="() => toggleSubMenu(index)"
          class="menu-item cursor-pointer hover:bg-gray-400 text-white px-4 py-2"
          v-if="menuItem.roles.includes(user['role'])"
        >
          <span>{{ menuItem.label }}</span>
        </div>
        <ul v-if="menuItem.showSubMenu" class="">
          <li
            v-for="(subMenuItem, subIndex) in menuItem.subMenu"
            :key="subIndex"
            class="pl-4 hover:bg-gray-400"
          >
            <div
              @click="() => goto(subMenuItem.to)"
              class="cursor-pointer py-2 text-white pl-4"
              v-if="subMenuItem.roles.includes(user['role'])"
            >
              {{ subMenuItem.label }}
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { get } from "vuex-pathify";

export default {
  name: "DrawerMenu",
  computed: {
    user: get("user"),
  },
  data() {
    return {
      menus: [
        { to: "/", label: "ໜ້າຫຼັກ", roles: ["ADMIN", "UNIT", "SHOP"] },
        {
          to: "/uploadcards",
          label: "ອັບໂຫຼດຂໍ້ມູນບັດ",
          roles: ["ADMIN"],
        },
        {
          to: "/cardlots",
          label: "ຂໍ້ມູນບັດ",
          roles: ["ADMIN"],
        },
        {
          to: "/formgroups",
          label: "ເອກະສານ - ຂະແໜງ",
          roles: ["ADMIN"],
        },
        {
          to: "/units",
          label: "ຫົວໜ້າໜ່ວຍ ແລະ ຮ້ານຄ້າ",
          roles: ["ADMIN"],
        },
        {
          to: `/unit`,
          label: "ຮ້ານຄ້າ",
          roles: ["UNIT"],
        },
        {
          label: "ລາຍງານ",
          showSubMenu: true,
          roles: ["ADMIN", "UNIT", "SHOP"],
          subMenu: [
            {
              label: "ການຂາຍລວມ",
              to: "/report/transactions",
              roles: ["ADMIN", "UNIT", "SHOP"],
            },
            {
              label: "ການຂາຍຕາມຫົວໜ້າໜ່ວຍ",
              to: "/report/transactionsbyunit",
              roles: ["ADMIN"],
            },
            {
              label: "ການຂາຍຕາມຮ້ານຄ້າ",
              to: "/report/transactionsbyshop",
              roles: ["ADMIN", "UNIT"],
            },
            {
              label: "ການເຄື່ອນໄຫວຫົວໜ້າໜ່ວຍ",
              to: "/report/statements",
              roles: ["ADMIN", "UNIT"],
            },
            {
              label: "ການເຄື່ອນໄຫວຮ້ານຄ້າ",
              to: "/report/shopstatements",
              roles: ["ADMIN", "UNIT", "SHOP"],
            },
            {
              label: "Commissions ຕົວແທນ ",
              to: "/report/shopcommissions",
              roles: ["ADMIN", "UNIT", "SHOP"],
            },
            {
              label: "Commissions ບໍລິສັດ",
              to: "/report/companycommissions",
              roles: ["ADMIN"],
            },
          ],
        },
        {
          label: "ການຕັ້ງຄ່າ",
          showSubMenu: true,
          roles: ["ADMIN"],
          subMenu: [
            {
              label: "ການຕັ້ງຄ່າລະບົບ",
              to: "/settings",
              roles: ["ADMIN"],
            },
            {
              to: "/users",
              label: "ຜູ້ໃຊ້ (Admin)",
              roles: ["ADMIN"],
            },
            {
              label: "ຜະລິດຕະພັນ",
              to: "/providers",
              roles: ["ADMIN"],
            },
          ],
        },
      ],
      isDrawerOpen: false,
    };
  },
  mounted() {},
  methods: {
    toggleSubMenu(index) {
      if (this.menus[index]["subMenu"]) {
        this.menus[index].showSubMenu = !this.menus[index].showSubMenu;
      } else {
        this.goto(this.menus[index].to);
      }
    },
    goto(route) {
      if (route !== this.$route.path) {
        if (route === "/unit") {
          if (this.user && this.user["role"] === "UNIT") {
            route = `/unit/${this.user["agentid"]}`;
          }
        }
        this.$router.push({ path: route });
      }
      this.isDrawerOpen = false;
    },
    toggleDrawer() {
      this.isDrawerOpen = !this.isDrawerOpen;
    },
    logout() {
      this.$store.set("user", null);
      this.$router.replace({ path: "/login" });
    },
  },
};
</script>
