import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("./views/HomePage.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/LoginPage.vue"),
  },
  {
    path: "/uploadcards",
    name: "uploadcards",
    component: () => import("./views/UploadCardsPage.vue"),
  },
  {
    path: "/cardlots",
    name: "cardlots",
    component: () => import("./views/CardLotsPage.vue"),
  },
  {
    path: "/cardlot",
    name: "cardlot",
    component: () => import("./views/CardLotPage.vue"),
  },
  {
    path: "/providers",
    name: "providers",
    component: () => import("./views/ProvidersPage.vue"),
  },
  {
    path: "/units",
    name: "units",
    component: () => import("./views/UnitsPage.vue"),
  },
  {
    path: "/unit/:id",
    name: "unit",
    component: () => import("./views/UnitPage.vue"),
  },
  {
    path: "/shop/:id",
    name: "shop",
    component: () => import("./views/ShopPage.vue"),
  },
  {
    path: "/users",
    name: "users",
    component: () => import("./views/UsersAdminPage.vue"),
  },
  {
    path: "/usersofagent",
    name: "usersofagent",
    component: () => import("./views/UsersOfAgentPage.vue"),
  },
  {
    path: "/report/transactions",
    name: "reporttransactions",
    component: () => import("./views/ReportTransactionsPage.vue"),
  },
  {
    path: "/report/transactionsbyunit",
    name: "reporttransactionsbyunit",
    component: () => import("./views/ReportTransactionsByUnitPage.vue"),
  },
  {
    path: "/report/transactionsbyshop",
    name: "reporttransactionsbyshop",
    component: () => import("./views/ReportTransactionsByShopPage.vue"),
  },
  {
    path: "/transaction",
    name: "transaction",
    component: () => import("./views/TransactionPage.vue"),
  },
  {
    path: "/report/statements",
    name: "reportstatements",
    component: () => import("./views/ReportStatementsPage.vue"),
  },
  {
    path: "/report/shopstatements",
    name: "reportshopstatements",
    component: () => import("./views/ReportShopStatementsPage.vue"),
  },
  {
    path: "/report/commissions",
    name: "reportcommissions",
    component: () => import("./views/ReportCommissionsPage.vue"),
  },
  {
    path: "/report/companycommissions",
    name: "reportcompanycommissions",
    component: () => import("./views/ReportCompanyCommissionsPage.vue"),
  },
  {
    path: "/report/shopcommissions",
    name: "reportshopcommissions",
    component: () => import("./views/ReportShopCommissionsPage.vue"),
  },
  {
    path: "/changepassword",
    name: "changepassword",
    component: () => import("./views/ChangePasswordPage.vue"),
  },
  {
    path: "/userprofile",
    name: "userprofile",
    component: () => import("./views/UserProfilePage.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("./views/SettingsPage.vue"),
  },
  {
    path: "/formgroups",
    name: "formgroups",
    component: () => import("./views/FormGroupsPage.vue"),
  },
  {
    path: "/formgroup/:id",
    name: "formgroup",
    component: () => import("./views/FormGroupPage.vue"),
  },
  {
    path: "/form/:id",
    name: "form",
    component: () => import("./views/FormPage.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  console.log("beforeEach.from", from);
  console.log("beforeEach.to", to);
  if (to.name === from.name && to.params === from.params) {
    next(false);
  }

  if (!["/login"].includes(to.path)) {
    const user = store.get("user");
    if (!user) {
      router.replace({ path: "/login" });
      return;
    }
  }

  next();
});
export default router;
