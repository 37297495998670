<template>
  <div class="h-full" id="app">
    <div class="h-full overflow-y-hidden">
      <div v-if="user" class="h-full flex">
        <div class="w-64">
          <DrawerMenu />
        </div>
        <div class="flex-1">
          <div class="py-4 bg-gray-600 flex text-white px-4">
            <div v-if="user" class="flex-1">
              {{ user["username"] ?? "" }}
              {{ agent && agent.name ? `ຫົວໜ້າໜ່ວຍ ${agent.name}` : "" }}
            </div>
            <div
              class="px-4 rounded cursor-pointer hover:bg-gray-400"
              @click="userprofile"
            >
              ແກ້ໄຂຂໍ້ມູນສ່ວນຕົວ
            </div>
            <div
              class="px-4 rounded cursor-pointer hover:bg-gray-400"
              @click="changepassword"
            >
              ປ່ຽນລະຫັດຜ່ານ
            </div>
            <div
              class="px-4 rounded cursor-pointer hover:bg-gray-400"
              @click="logout"
            >
              ອອກ
            </div>
          </div>
          <div class="bg-gray-100 h-full p-4 w-full">
            <router-view />
          </div>
        </div>
      </div>
      <router-view v-else />
    </div>
  </div>
</template>
<script>
import { get } from "vuex-pathify";
import DrawerMenu from "./components/DrawerMenu.vue";
import io from "socket.io-client";
export default {
  name: "App",
  components: { DrawerMenu },
  computed: {
    user: get("user"),
    agent: get("agent"),
    username: get("username"),
  },
  data() {
    return {
      isshowmenu: true,
      socket: null,
      messages: null,
      messageText: "",
    };
  },
  mounted() {
    this.socket = io(process.env.VUE_APP_URLAPI);
    this.socket.on("message", (data) => {
      if (!this.messages) {
        this.messages = [];
      }
      this.messages.push(data);
    });
  },
  methods: {
    sendMessage() {
      if (this.messageText.trim() === "") return;
      this.socket.emit("publish", {
        user: this.user,
        text: this.messageText,
      });
      this.messageText = "";
    },
    logout() {
      this.$store.set("user", null);
      this.$router.replace({ path: "/login" });
    },
    changepassword() {
      this.$router.replace({ path: "/changepassword" });
    },
    userprofile() {
      this.$router.replace({ path: "/userprofile" });
    },
  },
};
</script>
<style>
@font-face {
  font-family: NotoSansLao;
  src: url("@/assets/NotoSansLao-VariableFont_wdth,wght.ttf");
}
#app {
  font-family: NotoSansLao;
}
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}
.slide-enter,
.slide-leave {
  transform: translateX(-20px);
}
.label {
  @apply text-gray-600 font-extralight text-sm;
}
.value {
  @apply font-bold;
}
table {
  @apply min-w-full divide-y divide-gray-200;
}
tbody {
  @apply bg-white divide-y divide-gray-200;
}
th {
  @apply text-center py-3 text-xs font-medium text-gray-500 uppercase tracking-wider px-2;
}
td {
  @apply whitespace-nowrap py-4 text-sm text-gray-900 text-center px-2;
}
button.search {
  @apply bg-green-400 text-white rounded px-4 py-2;
}
button.view,
a.view {
  @apply bg-blue-400 text-white rounded p-1;
}
button.edit {
  @apply bg-yellow-400 text-white rounded p-1;
}
button.remove {
  @apply bg-red-400 text-white rounded p-1;
}
select.select {
  @apply border rounded py-2 px-4 w-full;
}
</style>
